import request from '@/utils/request'

export function getPriceList() {
    return request({
        url: '/order/getPriceList', 
        method: 'get'
    })
}

export function editPriceList(id,data) {
    return request({
        url: '/order/editPriceList?id=' + id,
        method: 'post',
        data
    })
}

export function getList(status,uid,pageNum,pageSize) {
    return request({
        url: '/order/list?status=' + status + '&uid=' + uid + '&pageNum=' + pageNum + '&pageSize=' + pageSize, 
        method: 'get'
    })
}

export function addList(data) {
    return request({
        url: '/order/save', 
        method: 'post',
        data
    })
}

export function editList(id,data) {
    return request({
        url: '/order/edit?id=' + id, 
        method: 'post',
        data
    })
}

export function delList(id) {
    return request({
        url: '/order/delete?id=' + id, 
        method: 'post',
    })
}

export function accountList(id,data) {
    return request({
        url: '/order/account?id=' + id, 
        method: 'post',
        data
    })
}