<template>
  <div>
    <div class="nav">
      <h1>业务管理/订单管理</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-select v-model="uid" clearable placeholder="请选择客户名称">
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <!-- <el-date-picker
            v-model="time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结价日期">
          </el-date-picker> -->
        </div>
      </div>
      <div class="right">
        <el-button type="" @click="getList()">搜索订单</el-button>
        <el-button type="primary" @click="saveOrder()">新增订单</el-button>
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-style="background: #f5f7fa;"
      >
        <el-table-column
          align="center"
          prop="trading_code"
          width="200"
          :show-overflow-tooltip="true"
          label="交易单号"
          fixed="left"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="name"
          :show-overflow-tooltip="true"
          label="客户名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="earnest"
          :show-overflow-tooltip="true"
          width="120"
          label="订单定金(元)"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="order_pricer"
          :show-overflow-tooltip="true"
          width="120"
          label="该订单金价(元)"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="latest_price"
          :show-overflow-tooltip="true"
          width="100"
          label="结算金价(元)"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="time"
          width="110"
          :show-overflow-tooltip="true"
          label="交易日期"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="weight"
          :show-overflow-tooltip="true"
          width="150"
          label="交易重量(g)"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="type"
          :show-overflow-tooltip="true"
          label="交易类型"
        >
        
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.type == 1 ? 'danger' : 'success'"
            >{{
              scope.row.type == 1 ? "定价销售" : "定价回购"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="order_status"
          :show-overflow-tooltip="true"
          label="订单状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.order_status == 1 ? 'danger' : 'success'"
              >{{ scope.row.order_status == 1 ? "未结算" : "已结算" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ingredients_name"
          :show-overflow-tooltip="true"
          label="原料名称"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="order_pricer"
          :show-overflow-tooltip="true"
          width="150"
          label="预定金价(元)"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="latest_price"
          :show-overflow-tooltip="true"
          width="150"
          label="结算金价(元)"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="frozen_price"
          :show-overflow-tooltip="true"
          width="150"
          label="盈亏合计(元)"
        >
        <template slot-scope="scope">
          <p>{{ scope.row.yingkui.toFixed(2) }}</p>
        </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          :show-overflow-tooltip="true"
          label="备注"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createtime"
          width="170"
          label="创建时间"
        ></el-table-column>
        <!-- <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column> -->
        <el-table-column align="center" label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="checkOrder(scope.row)"
                effect="dark"
                content="定价单"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-s-grid"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="editData(scope.row)"
                effect="dark"
                content="编辑订单"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除订单"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <operationOrder
      :dialogVisible="dialogVisible"
      :data="con"
      @outClose="dialogVisible = false"
    />

    <!-- 定价单 -->
    <el-dialog
      title="延迟来料定价单"
      :visible.sync="orderDialogVisible"
      :before-close="outClose"
      width="800px"
    >
      <h3 style="color: #000;font-size: 23px;text-align: center;height: 54px;">延迟来料定价单</h3>
      <div class="">
        <!-- <p style="color:blue">客户：{{ orderInfo.name }}</p>
        <p style="color:blue">可用金额：{{ withdraw }}</p> -->
      <div class="top" style="display: flex;justify-content: space-around; margin-bottom: 20px;">
        <div class="" style="width:50%">
          <p style="font-weight: 600; font-size: 14px;color: #000;">客户：{{ orderInfo.name }}</p>
          <p style="font-weight: 600; font-size: 14px;color: #000;">日期：{{ orderInfo.time }}</p>
        </div>
        <div class="" style="width:50%">
          <!-- <p style="font-weight: 500; font-size: 16px;color: #000;">交易单号：{{ userInfo }}</p> -->
          <p style="font-weight: 600; font-size: 14px;color: #000;text-align: right;">交易单号：{{ orderInfo.trading_code }}</p>
          <p style="font-weight: 600; font-size: 14px;color: #000;text-align: right;">业务员：周思维</p>
        </div>
      </div>
      </div>
      <table border="1" cellpadding="15px" cellspacing="0" style="width: 100%;margin: auto;text-align: center">
        <tbody>
          <!-- <tr>
            <td colspan="4" style="font-size: 11px;font-weight: 1000;">客户名称</td>
            <td colspan="5" style="font-size: 11px;font-weight: 1000;">{{ orderInfo.name }}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">备注</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">{{ orderInfo.remark }}</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">单号</td>
            <td colspan="3" style="font-size: 11px;font-weight: 1000;">{{ orderInfo.trading_code }}</td>
          </tr>

          <tr>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">交易日期</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">{{ orderInfo.time }}</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">业务员</td>
            <td colspan="3" style="font-size: 11px;font-weight: 1000;">管理员</td>
          </tr> -->

          <tr>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">原料名称</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易重量（克）</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易单价</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易金额（元）</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">定金金额</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易类型</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">备注</td>
          </tr>

          <tr>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.ingredients_name }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.weight }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer * orderInfo.weight }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.earnest }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{  orderInfo.type == 1 ? "定价销售" : "定价回购" }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.remark }}</td>
            
            <!-- <td style="font-size: 13px;font-weight: 800;color: #000000;">定金金额</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易类型</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">100.0</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{  orderInfo.order_status == 1 ? "定价销售" : "定价回购" }}</td> -->
          </tr>

          <tr>
            <td colspan="1" style="font-size: 11px;font-weight: 1000;">合计</td>
            <td style="font-size: 11px;font-weight: 800;">{{ orderInfo.weight }}</td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;"></td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer * orderInfo.weight }}</td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;">{{ orderInfo.earnest }}</td>
            <td colspan="3" style="font-size: 11px;font-weight: 800;color: #000000;"></td>
          </tr>
        </tbody>
      </table>
      <br>
      <p style="margin: 0 0 20px;font-weight:600;line-height: 26px;font-weight: 800;color: #000000;">
        温馨提示：<br>
        1：购销双方本着公平交易，互利互惠原则达成购销协议 <br>
        2：客户承诺以上出售所有物品是合法所有，不是赃物或违法所得，本人愿意承担经济和法律责任 <br>
        3：来料提料请提前一天通知 <br><br>
      </p>
      <p style="font-weight: 600; font-size: 14px;color: #000;margin-top:15px;position: relative;top: -13px;">&nbsp;&nbsp;制单：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 复核：  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客户签名：</p>
    </el-dialog>
  </div>
</template>
  
  <script>
import operationOrder from "./components/operationOrder.vue";
import { getList, delList } from "@/api/order";
import { getList as getUserList } from "@/api/user"
export default {
  components: {
    operationOrder,
  },
  inject: ["reload"],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      item: [],
      tableData: [],
      userList: [],
      classifyList: [],
      dialogVisible: false,
      orderDialogVisible: false,
      con: {
        ingredients_name: '足金板料'
      },
      orderInfo: {},
      amount: "",
      type: 0,
      uid: '',
    };
  },
  created() {
    this.getList();
    getUserList(1,100,'').then(res => {
      console.log(res)
      this.userList = res.data.list
    })
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
        this.amount = "";
      }
    },
  },
  methods: {
    checkOrder(data) {
      this.orderInfo = data
      this.orderDialogVisible = true;
    },
    saveOrder() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editData(data) {
      let cons = {
        id: data.id,
        closing_time: data.closing_time,
        earnest: data.earnest,
        ingredients_name: data.ingredients_name ? data.ingredients_name : '足金板料',
        latest_price: data.latest_price,
        order_pricer: data.order_pricer,
        order_status: data.order_status,
        remark: data.remark,
        time: data.time,
        trading_code: data.trading_code,
        type: data.type,
        uid: data.uid,
        weight: data.weight,
        demurrage: data.demurrage
      };
      this.con = JSON.parse(JSON.stringify(cons));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delList(id).then((res) => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    getList() {
      getList(1,this.uid,this.pageNum,this.pageSize).then((res) => {
        res.data.list.forEach(list => {
          if(list.type == 1) {
            list['yingkui'] = (parseFloat(list.latest_price) - parseFloat(list.order_pricer)) * list.weight
          } else {
            list['yingkui'] = (parseFloat(list.order_pricer) - parseFloat(list.latest_price)) * list.weight
          }
        });
        this.tableData = res.data.list;
        this.total = res.data.total
      });
    },
    // getList() {
    //   getList(1,'','').then((res) => {
    //     res.data.forEach(list => {
    //       if(list.type == 1) {
    //         list['yingkui'] = (parseFloat(list.latest_price) - parseFloat(list.order_pricer)) * list.weight
    //       } else {
    //         list['yingkui'] = (parseFloat(list.order_pricer) - parseFloat(list.latest_price)) * list.weight
    //       }
    //     });
    //     this.tableData = res.data;
    //   });
    // },
  },
};
</script>
  
  <style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 13px;
  p {
    font-size: 15px;
    color: rgba(125, 133, 146, 1);
    margin-bottom: 10px;
  }
  .el-input {
    width: 100%;
  }
}

.list-dialog {
  //   margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .list {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}

td {
  height: 25px;
}
</style>