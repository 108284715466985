<template>
  <div>
    <el-dialog
      :title="data.id ? '编辑订单' : '添加订单'"
      :visible.sync="dialogVisible"
      :before-close="outClose"
      width="600px"
    >
      <div class="from">
        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              客户
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-select v-model="data.uid" placeholder="请选择">
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              定金(元)
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.earnest"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog" v-if="data.id">
          <div class="list">
            <p style="margin-bottom: 10px">
              结算金价(元)
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.latest_price"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              该订单金价(元)：
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.order_pricer"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list" v-if="data.id">
            <p style="margin-bottom: 10px">
              延期费
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.demurrage"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              交易日期
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-date-picker 
            v-model="data.time" 
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              交易重量(g)
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input v-model="data.weight" placeholder="请输入内容"></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              交易类型
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-select v-model="data.type" placeholder="请选择">
              <el-option
                v-for="item in tradingList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="list-dialog" v-if="data.order_status">
          <div class="list">
            <p style="margin-bottom: 10px">
              订单状态
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-select v-model="data.order_status" placeholder="请选择">
              <el-option
                v-for="item in orderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="list-dialog" v-if="data.id">
          <div class="list">
            <p style="margin-bottom: 10px">
              交易单号
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.trading_code"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              原料名称
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input
              v-model="data.ingredients_name"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px">
              备注
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-input v-model="data.remark" placeholder="请输入内容"></el-input>
          </div>
        </div>

        <div class="list-dialog" v-if="data.id">
          <div class="list">
            <p style="margin-bottom: 10px">
              结价日期
              <span style="color: red; margin-left: 0px; font-weight: 500"
                >*</span
              >
            </p>
            <el-date-picker
              v-model="data.closing_time"
              type="date"
             value-format="yyyy-MM-dd"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="outClose()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getList } from "@/api/user";
import { addList,  accountList as editList  } from "@/api/order";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    fid: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 0,
      tradingList: [
        {
          value: 1,
          label: "定价销售",
        },
        {
          value: 2,
          label: "定价回购",
        },
      ],
      orderList: [
        {
          value: 1,
          label: "未结算",
        },
        {
          value: 2,
          label: "已结算",
        },
      ],
      userList: [],
    };
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        for (let key in this.data) {
          this.data[key] = "";
        }
      } else {
        getList(1,100,'').then((res) => {
          if (res.code == 200) {
            this.userList = res.data.list;
          }
        });
      }
    },
  },
  methods: {
    logoUploadSuccess(res) {
      this.$refs.upload.clearFiles();
      if (res.code == 200) {
        this.data.file = "http://www.therarna.cn/" + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },

    logoUploadSuccess2(res) {
      this.$refs.upload.clearFiles();
      if (res.code == 200) {
        this.data.imgs = "http://www.therarna.cn/" + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },

    logoRemove() {
      this.data.imgs = "";
      this.$forceUpdate();
    },

    remove() {
      this.data.file = "";
      this.$forceUpdate();
    },
    submit() {
      if (this.data.id) {
        this.edit();
      } else {
        this.add();
      }
    },
    add() {
      this.data.order_status = 1
      addList(this.data).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.$emit("outClose");
        }
      });
    },
    edit() {
      editList(this.data.id, this.data).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("outClose");
        }
      });
    },
    outClose() {
      this.$emit("outClose");
    },
  },
};
</script>
  
  <style lang="less" scoped>
.from {
  height: 500px;
}
/deep/ .el-upload-list {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}
/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
.list-dialog {
  //   margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .list {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}
.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .left {
    width: 100%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      .el-input {
        width: 300px;
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }
  .right {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
    }
  }
}
.table {
  margin-top: 30px;
  .t-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    .t-list {
      width: 19%;
      background: #fff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.3);
      padding-top: 20px;
      border-radius: 5px;
      margin-bottom: 1%;
      position: relative;
      p {
        text-align: center;
        margin-bottom: 0px !important;
      }
      .title {
        font-weight: 800;
        font-size: 15px;
        opacity: 0.5;
      }
      .img {
        width: 100%;
        height: auto;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        margin: 20px auto;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: block;
          margin: auto;
        }
      }
      .number {
        color: #000;
        font-weight: 800;
        font-size: 14px;
        opacity: 0.8;
      }
      .value {
        color: #000;
        opacity: 0.3;
        font-size: 12px;
        margin-top: 5px;
      }
      .datas {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        position: relative;
        top: 13px;
        padding-bottom: 20px;
        .box {
          position: absolute;
          left: 38%;
          transform: translateX(-30%);
          img {
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 50%;
            position: absolute;
          }
        }
      }
      .time {
        font-weight: 800;
        font-size: 13px;
        padding-bottom: 20px;
        opacity: 0.5;
        margin-top: 5px;
      }
      .edit {
        position: absolute;
        right: 18px;
        top: 15px;
        color: #000;
        opacity: 0.4;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
}
.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 13px;
  p {
    font-size: 15px;
    color: rgba(125, 133, 146, 1);
    margin-bottom: 10px;
  }
  .el-input {
    width: 100%;
  }
}

/deep/ .ql-snow .ql-editor img {
  max-width: 30% !important;
}

.itemIms {
  width: auto;
  display: flex;
  .img_list {
    width: 100%;
    height: auto;
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    i {
      cursor: pointer;
    }
    .el-icon-document {
      font-weight: 700;
    }
    p {
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // line-height: 110px;
      // background: rgba(0, 0, 0, 0.8);
      // top: 0;
      // color: #fff;
      // text-align: center;
      // cursor: pointer;
      // opacity: 0;
      // transition: 0.3s;
      // border-radius: 5px;
      // text-align: center;
      // display: block;
      // font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}

.itemIms2 {
  width: auto;
  display: flex;
  .img_list {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 110px;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      border-radius: 5px;
      text-align: center;
      display: block;
      font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}
</style>